import request from '@/utils/request'

//用户答题详情(本接口涉及到答案的只有批阅之后才给显示)
export function gettestperioduserInfo(params) {
    return request({
        url: '/portal/test-period-user/get-info',
        method: 'GET',
        params
    })
}

//试卷答题保存/提交
export function testperioduseranswer(data) {
    return request({
        url: '/portal/test-period-user/answer',
        method: 'POST',
        data
    })
}